.sortable-unit-ghost,
.sortable-ghost {
  /* box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.75); */
  opacity: 0.3 !important;
  background-color: #f6f6f6;

  /* box-shadow: 0px 0px 0px 0.7px inset  orange; */
  
}
.sortable-unit-drag,
.sortable-drag {
  box-shadow: 0px 3px 6px 0.5px rgba(0,0,0,0.5);
  opacity: 1 !important;
  
}

.sortable-unit-fallback,
.sortable-fallback {
  box-shadow: 0px 3px 6px 0.5px rgba(0,0,0,0.5);
  opacity: 1 !important;
  background-color: hsl(0, 0%, 93%);

}

.sortable-unit-drag {
  min-height: 4em;
  background-color: hsl(0, 0%, 93%);
  transform: translateY(-10px);
}
.sortable-unit-ghost {

}
.sortable-unit-fallback {
  padding-top: 1em;
  min-height: 5em;
  max-width: 5em !important;
  background-color: hsl(0, 0%, 93%);
  transform: translateY(-10px);
}