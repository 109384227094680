.react-tel-input .country-list .country-name {
	color: black;
}


.react-tel-input .flag-dropdown {
	background-color: transparent;
    border: none;
    border-radius: 0px;
}


.react-tel-input .selected-flag {
    z-index: 13;
    position: relative;
    width: 41px;
    background-color: white;
    border: none;
	cursor: pointer;
}


.react-tel-input input[type='text'], .react-tel-input input[type='tel'] {	
    padding-left: 14px;
    height: 28px;
    width: 325px;
    margin-left: 44px;
}