/* Workaround for set font-size by package */
textarea[class^="CustomInput-"],
textarea[class*=" CustomInput-"] {
	font-size: inherit !important;
}

textarea[class^="CustomInput-"]::-webkit-scrollbar,
textarea[class*=" CustomInput-"]::-webkit-scrollbar {
	display: none;
}
