.DraggableTableHeader {
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  padding: 2px 4px;
  text-align: left;
  position: relative;
  background-color: white;
  outline: none !important;
}

.DraggableTableHeader div {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.cursor-grab {
  cursor: grab;
}

.cursor-grab:active {
  cursor: grabbing;
}

.CustomCell {
  /* border-right: 1px solid lightgray; */
  padding: 2px 4px;
}

.CustomCell:last-child {
  border-right: 0;
}

.resizer {
  position: absolute;
  top: 0;
  height: 100%;
  width: 5px;
  background: rgba(0, 0, 0, 1);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.ltr {
  right: 0;
}

.resizer.rtl {
  left: 0;
}

.resizer.isResizing {
  background: blue;
  opacity: 1;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
    transition: opacity 0.2s;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}
.ObjectsWrapperView_root {
  overflow: auto;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
}
.height-without-header {
  height: calc(100vh - 130px);
}
.ObjectsWrapperListViewTanStackTable_root {
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow: scroll;
}

.ObjectsWrapperListViewTanStackTable_table {
}

.ObjectsWrapperListViewTanStackTable_table_tbody {
  /* border-bottom: 1px solid lightgray; */
}

.ObjectsWrapperListViewTanStackTable_table_tr_spacer {
  height: 6px;
}

.ObjectsWrapperListViewTanStackTable_table_tr {
  /* border: 1px solid lightgray; */
  cursor: pointer;
}

.ObjectsWrapperListViewTanStackTable_table_tr__active {
  border: 2px solid rgb(242, 125, 34);
}

.ObjectsWrapperListViewTanStackTable_table_cell > div {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 0px;
}

.ObjectsWrapperListViewTanStackTable_table_cell__th > div,
.ObjectsWrapperListViewTanStackTable_table_cell__td {
  border-top: 1px solid rgb(111, 111, 111, 0.36) !important;
  border-left: 1px solid rgb(111, 111, 111, 0.36) !important;
  height: 100% !important;
  background-color: white;
  outline: none !important;
  outline-offset: none !important;
}

.ObjectsWrapperListViewTanStackTable_table_tbody .ObjectsWrapperListViewTanStackTable_table_cell__td {
  border-bottom: 1px solid rgb(111, 111, 111, 0.36) !important;
}

.ObjectsWrapperListViewTanStackTable_table_cell__td > div {
  height: 40px;
  vertical-align: middle;
}

.ExpandableTableCell_arrowIcon {
  height: 10px;
  opacity: 1;
  bottom: 15px;
  transition: opacity 1s;
}

.ExpandableTableCell {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.ExpandableTableCell_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 8px;
  cursor: pointer;
}
.ExpandableTableCell_value {
  padding: 0 8px;
}

.DraggableTableHeader_drag-handle,
.DraggableTableHeader_Filter {
  opacity: 0;
  transition: opacity 0.2s;
}

.DraggableTableHeader:hover {
  .DraggableTableHeader_drag-handle,
  .DraggableTableHeader_Filter {
    opacity: 1 !important;
  }
}

.DraggableTableHeader_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.DraggableTableHeader_text {
  padding: 12px;
  overflow: auto;
  width: inherit;
  text-wrap: wrap;
  justify-content: center;
}
.DraggableTableHeader_text div {
  justify-content: start;
}

.DraggableTableHeader_container__group-header {
  align-items: start !important;
}

.DraggableTableHeader_container__group-header .DraggableTableHeader_text {
  font-size: 18px;
  color: rgb(242, 125, 34);
  font-weight: 700;
  text-align: start;
}

.DraggableTableHeader_container__child-header .DraggableTableHeader_text {
  text-align: start;
  font-weight: 700;
  font-size: 14px;
  margin-left: 7px;
  height: 74px;
}

.ExpandableTableCell_count {
  min-width: 25px;
  min-height: 25px;
  width: 25px;
  height: 25px;
  display: flex;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  left: 6px;
  top: 8px;
  font-size: 14px;
}

.primaryGroupByBackground {
  background-color: #e4e4e4 !important;
}
.secondaryGroupByBackground {
  background-color: #f2f2f2 !important;
}
.tableRowBackground {
  background-color: #ffffff !important;
}
.tableHoverBackground:hover {
  background-color: #fafafa !important;
}

.OW-HeaderContext .contextMenu {
  --contexify-menu-bgColor: #fff;
  --contexify-separator-color: rgba(0, 0, 0, 0.2);
  --contexify-item-color: #333;
  --contexify-activeItem-color: #000;
  --contexify-activeItem-bgColor: #f2f2f2;
  --contexify-rightSlot-color: #6f6e77;
  --contexify-activeRightSlot-color: #fff;
  --contexify-arrow-color: #6f6e77;
  --contexify-activeArrow-color: #fff;
  --contexify-menu-minWidth: 100px;
  --contexify-menu-shadow: none !important;
  border: 2px solid rgb(222, 224, 229);
  display: flex;
  flex-direction: column;
  z-index: 7;
  margin-top: 12px;
}

.contexify_item,
.contexify_itemContent {
  width: 100%;
}

.GroupsControls_container {
  position: relative;
  z-index: 6;
}
.GroupsControls_header-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 17px 7px 17px;
  border: 2px solid rgb(222, 224, 229);
  border-radius: 4px;
  transform: scale(0.82);
  margin: 12px;
  font-size: 16px;
  line-height: 25px;
}
.GroupsControls_menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  padding: 16px;
  border-radius: 4px;
  border: 2px solid rgb(222, 224, 229);
  background-color: #fff;
  transform: translate(21px, 70px);
  z-index: 3;
  gap: 8px;
}

.GroupsControls_menu_item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.cursor-pointer {
  cursor: pointer;
}

.DraggableTableHeader_Filter {
  position: absolute;
  bottom: 0;
}

.DraggableTableHeader_Filter p {
  margin: 0;
}

.ObjectsWrapperListViewTanStackTable_table_thead {
  position: sticky;
  top: 0;
  margin: 0;
  z-index: 5;
}

.ObjectsWrapperView_content {
  height: 100%;
}

.ObjectsWrapperView_loading-container {
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  backdrop-filter: blur(0px);
  transition: backdrop-filter 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.ObjectsWrapperView_loading-container__visible {
  visibility: visible;
  background-color: rgba(255, 255, 255, 1);
}

.ObjectsWrapperView_empty-icon {
  width: 200px;
  height: 200px;
  fill: rgb(242, 125, 34);
  margin: 0 auto;
  justify-self: center;
  align-self: center;
}

.ObjectsWrapperGridView_root {
  width: 100%;
  overflow: auto;
}

.CustomCell_base {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 100%;
  width: 100%;
  text-align: center;
  line-height: 40px;
  display: block;
}
.DraggableTableHeader_header {

  border-bottom: 1px solid rgb(111, 111, 111, 0.36) !important;
  border-right: 1px solid rgb(111, 111, 111, 0.36) !important;
  height: 45px;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.DraggableTableHeader_header > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.DraggableTableHeader > main {
  border-top: 1px solid rgb(111, 111, 111, 0.36) !important;
}
.DraggableTableHeader_border {
  border-left: 1px solid rgb(111, 111, 111, 0.36) !important;
}
.DraggableTableHeader_header_empty {
  border-left: none !important;
  border-right: none !important;
}
