.ColumnVisibilityControls_root {
  position: relative;
  z-index: 7;
  display: flex;
}
.ColumnVisibilityControls_button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 17px 7px 17px;
  border: 1.5px solid rgb(222, 224, 229);
  border-radius: 4px;
  transform: scale(0.82);
  font-size: 16px;
  line-height: 25px;
}
.ColumnVisibilityControls_button_label {
  margin: 0 4px;
}
.ColumnVisibilityControls_popover {
  position: absolute;
  width: 240px;
  visibility: hidden;
  background-color: white;
  border: 1.5px solid rgb(222, 224, 229);
  border-radius: 4px;
  padding-left: 13px;
  left: 25px;
  top: 70px;
}
.ColumnVisibilityControls_popover__open {
  visibility: visible;
}
.ColumnVisibilityControls_section {
  opacity: 0.8;
  font-size: 18px;
  text-transform: uppercase;
}
.ColumnVisibilityControls_checkbox {
  margin-right: 12px;
}
