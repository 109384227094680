.IssueStateCell_root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.IssueStateCell__icon {
  margin: 0 6px;
}

.IssueStateCell_summary {
  display: flex;
  width: 100%;
}
