:root {
  --brand-primary: #F77D04;
  --brand-primary-faded: #F77D0420;
  --scroll-color: var(--brand-primary);
  --window-inactive-scroll-color: rgba(0,0,0,0.5);
  --scroll-width: 11px;
  --scroll-width-hover: 14px;
}

html {
  scrollbar-color: var(--brand-primary) transparent;
}

div p span {
  font-size: '14px';
  font-family: 'Assistant';
  font-weight: "300";
  color: '#2c2c2c';
  line-height: "1.5em";
}
input{
  background: inherit;
  border: none;
  font-family: inherit;
  font-size: inherit;
}

::-webkit-scrollbar {
  height: var(--scroll-width);
  width: var(--scroll-width);
  cursor: grab;
}

::-webkit-scrollbar-thumb:hover::-webkit-scrollbar {
  width: var(--scroll-width-hover);
  height: var(--scroll-width-hover);
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--window-inactive-scroll-color);
  border-radius: 10px; 
  -webkit-border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--scroll-color);
  border: 10px solid var(--scroll-color);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: var(--window-inactive-scroll-color); 
}